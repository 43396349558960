import { DateTime } from 'luxon'
import { propOr } from 'ramda'

import { RATING_SCALE } from 'lib/constants/reviews'

export const formatFilterDate = date => DateTime.fromJSDate(date).toFormat('d MMM y')

export const percentageByRating = (rating, meta) => {
  const { total } = meta

  if (total === 0) return 0

  return (propOr(0, `${rating}StarTotal`, meta) * 100) / total
}

export const ratingsWithProgress = meta =>
  RATING_SCALE.map(rating => ({
    rating,
    count: propOr(0, `${rating}StarTotal`, meta),
    percentage: percentageByRating(rating, meta),
  }))
