import { any } from 'ramda'

import isPresent from 'utils/isPresent'

const isSocialNetworksAvailable = ({
  facebookUrl,
  instagramUrl,
  whatsupUrl,
  youtubeUrl,
  yelpUrl,
  twitterUrl,
  linkedinUrl,
}) => any(isPresent)([facebookUrl, instagramUrl, whatsupUrl, youtubeUrl, yelpUrl, twitterUrl, linkedinUrl])

export default isSocialNetworksAvailable
