import PropTypes from 'prop-types'

import profileLocationWithZipCode from 'utils/profileLocationWithZipCode'
import LocationO from 'views/shared/icons/LocationO'

const Address = ({ address, city, country, zipCode }) => (
  <div className="d-flex mb-24">
    <LocationO className="in-blue-600" size={20} />
    <span className="ws-footer__conact-item text-subheader ml-16" data-cy="footer-company-address">
      {address && (
        <>
          {address} <br />
        </>
      )}
      {(city || country || zipCode) && profileLocationWithZipCode({ city, country, zipCode })}
    </span>
  </div>
)

Address.defaultProps = {
  address: undefined,
  city: undefined,
  country: undefined,
  zipCode: undefined,
}

Address.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  zipCode: PropTypes.string,
}

export default Address
