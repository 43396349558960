import PropTypes from 'prop-types'

import { durationToFormatString } from 'utils/duration'
import ShowLessButton from 'views/Website/ShowLessButton'
import ShowMoreButton from 'views/Website/ShowMoreButton'
import BookingButton from 'views/Website/BookingButton'
import ShowMoreText from 'views/shared/ShowMoreText'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { showSidebar } from 'state/sidebar/actions'
import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'

const Service = ({ id, name, duration, description, ...serviceProps }) => {
  const handleShowService = useDispatchAction(showSidebar, {
    sidebarType: SIDEBAR_COMPONENTS_TYPES.websiteViewService,
    sidebarProps: { ...serviceProps, name, duration, id, description },
    showOverlay: true,
  })

  return (
    <div
      className="ws-service__card ws-interactive-element"
      data-cy="ws-service-card"
      onClick={handleShowService}
      role="button"
    >
      <div className="ws-service__card-info">
        <p className="mb-2 text-title font-600" data-cy="service-name">
          {name}
        </p>
        <p className="text-subheader in-gray-700 mb-0" data-cy="service-duration">
          {durationToFormatString(duration, { isLong: true, omitZeroes: true })}
        </p>
        <ShowMoreText
          lines={3}
          text={description}
          className="mt-12 mb-0 text-subheader ws-interactive-element"
          showMoreComponent={ShowMoreButton}
          showLessComponent={ShowLessButton}
        />
      </div>
      <div>
        <BookingButton serviceId={id} className="ws-interactive-element" />
      </div>
    </div>
  )
}

Service.defaultProps = {
  description: '',
}

Service.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  description: PropTypes.string,
}

export default Service
