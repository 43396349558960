export const widgetStatus = values => {
  const { active, status } = values

  if (status) return status
  return active ? 'active' : 'inactive'
}

export const buildWidgetBootOptions = ({ values, appId, workspaceCode }) => ({
  isPreviewMode: true,
  appId,
  workspaceId: workspaceCode,
  widgetSettings: {
    ...values,
    status: widgetStatus(values),
  },
})

export const bootWidgetPreview = (options, isExpanded) =>
  window.ExpertBox?.boot?.(options ? buildWidgetBootOptions(options) : null, options ? isExpanded : false)

export const bootWidget = ({ widget, workspace, currentClient, serviceId, providerId }, isExpanded) =>
  widget &&
  window.ExpertBox?.boot?.(
    {
      appId: widget.id,
      workspaceId: workspace.uniqCode,
      widgetSettings: widget,
      currentClient,
      serviceId,
      providerId,
    },
    isExpanded,
  )
