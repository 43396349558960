import { FormattedMessage } from 'react-intl'

import OnlineAppointment from './OnlineAppointment'
import OfflineLocations from './OfflineLocations'

const Locations = () => (
  <div className="ws-section" data-cy="locations-section">
    <div className="container container ws-container--full-mob">
      <p className="font-600 ws-section__title text-center" id="locations" data-cy="locations-section-title">
        <FormattedMessage id="website.page.locations.title" />
      </p>
      <OnlineAppointment />
      <OfflineLocations />
    </div>
  </div>
)

export default Locations
