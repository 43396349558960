import PropTypes from 'prop-types'
import Image from 'next/legacy/image'
import { round } from 'lodash'

import profileName from 'utils/profileName'
import Button from 'views/shared/Button'
import StarSolid from 'views/shared/icons/StarSolid'
import avatarPlaceholder from 'assets/images/website/avatar-placeholder.jpg'
import { MAX_TEXT_LENGTH } from 'lib/constants/website'
import { ROUND_PRECISION } from 'lib/constants/reviews'
import BookingButton from 'views/Website/BookingButton'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { showSidebar } from 'state/sidebar/actions'
import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'

const Provider = ({
  id,
  firstName,
  lastName,
  position,
  publishedReviewsCount,
  publishedRating,
  about,
  avatarUrls,
  ...providerProps
}) => {
  const handleShowProvider = useDispatchAction(showSidebar, {
    sidebarType: SIDEBAR_COMPONENTS_TYPES.websiteViewProvider,
    sidebarProps: {
      ...providerProps,
      id,
      firstName,
      lastName,
      position,
      publishedReviewsCount,
      publishedRating,
      about,
      avatarUrls,
    },
    showOverlay: true,
  })

  return (
    <div className="ws-expert__item" role="button" data-cy="expert-item">
      <div className="w-100 d-flex align-items-start">
        {avatarUrls?.original ? (
          <Image
            src={avatarUrls.original}
            className="ws-expert__img"
            alt="expert-avatar"
            width={128}
            height={128}
            data-cy="expert-avatar"
          />
        ) : (
          <Image
            src={avatarPlaceholder}
            className="ws-expert__img"
            alt="expert-placeholder"
            width={128}
            height={128}
            data-cy="expert-placeholder"
          />
        )}
        <div className="ml-24 ws-expert__item-info">
          <div className="d-flex mb-12 align-items-start justify-content-between">
            <div>
              <p className="text-title font-600 mb-2" data-cy="expert-name">
                {profileName({ firstName, lastName })}
              </p>
              <p className="mb-0 text-subheader in-gray-700" data-cy="expert-position">
                {position}
              </p>
            </div>
            {publishedReviewsCount > 0 && (
              <div className="d-flex align-items-center rate-qwt bg-gray-10">
                <StarSolid size={18} className="in-yellow-500" dataCy="star-icon" />
                <span className="text-subheader in-blue-gray-300 ml-8 mt-4 font-600" data-cy="expert-rating">
                  {round(publishedRating, ROUND_PRECISION)}
                </span>
              </div>
            )}
          </div>
          {about && (
            <p className="text-subheader txt-ellipsis-3 mb-0" data-cy="about-expert">
              {about.length > MAX_TEXT_LENGTH ? `${about.slice(0, MAX_TEXT_LENGTH)}...` : about}
            </p>
          )}
        </div>
      </div>
      <div className="d-flex pt-24 mt-auto">
        <Button
          text={{ id: 'website.page.providers.viewProfile' }}
          kind="secondary"
          className="w-100 mr-16 ws-interactive-element"
          size="medium"
          onClick={handleShowProvider}
        />
        <BookingButton className="w-100 ws-interactive-element" providerId={id} />
      </div>
    </div>
  )
}

Provider.defaultProps = {
  position: null,
  about: null,
  avatarUrls: null,
}

Provider.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.string,
  publishedReviewsCount: PropTypes.number.isRequired,
  publishedRating: PropTypes.string.isRequired,
  about: PropTypes.string,
  avatarUrls: PropTypes.shape(),
}

export default Provider
