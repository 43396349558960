import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Phone = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M223.402 0.571c-20.572 0-40.836 7.369-57.723 20.878l-2.456 1.228-1.228 1.228-122.814 126.499 1.228 1.228c-37.919 35.003-49.586 87.352-33.16 132.64 0.153 0.307-0.154 0.921 0 1.228 33.313 95.334 118.516 279.403 284.93 445.818 167.029 167.026 353.55 248.853 445.818 284.929h1.229c47.744 15.964 99.476 4.608 136.32-27.018l124.047-124.042c32.543-32.548 32.543-89.042 0-121.585l-159.663-159.663-1.229-2.458c-32.543-32.543-90.266-32.543-122.813 0l-78.603 78.603c-28.401-13.66-96.102-48.666-160.886-110.531-64.324-61.409-97.177-132.027-109.305-159.66l78.602-78.602c33.007-33.007 33.62-87.966-1.228-120.359l1.228-1.228-3.684-3.684-158.431-163.343-2.456-1.228c-16.887-13.51-37.151-20.878-57.723-20.878zM223.402 79.173c2.917 0 5.834 1.382 8.597 3.684l160.888 164.572c-0.307-0.307 2.303 3.838-2.456 8.597l-98.252 98.251-18.422 17.194 8.597 24.563c0 0 45.134 120.818 140.008 211.242l8.597 7.368c91.342 83.359 198.959 128.957 198.959 128.957l24.566 11.049 116.669-116.669c6.758-6.758 5.529-6.758 12.283 0l160.887 160.887c6.758 6.753 6.758 4.296 0 11.054l-120.357 120.357c-18.115 15.504-37.305 18.729-60.18 11.054-89.042-35-262.054-110.996-416.341-265.278-155.515-155.516-236.725-331.909-266.509-417.572-5.987-15.966-1.689-39.608 12.282-51.582l2.456-2.457 119.13-121.586c2.763-2.302 5.68-3.684 8.597-3.684z" />
  </svg>
)

Phone.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Phone.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Phone
