import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const StarSolid = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 512 512"
    data-cy={dataCy}
  >
    <path d="m256.001 412.529 135.587 81.834c19.571 11.812 31.248 3.434 26.032-18.923l-35.981-154.235 119.79-103.774c17.29-14.979 12.945-28.679-9.92-30.619l-157.745-13.383-61.555-145.237c-8.95-21.119-23.383-21.307-32.413 0l-61.555 145.237-157.745 13.383c-22.77 1.931-27.283 15.578-9.92 30.619l119.79 103.774L94.385 475.44c-5.194 22.262 6.379 30.784 26.032 18.923l135.587-81.834z" />
  </svg>
)

StarSolid.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

StarSolid.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default StarSolid
