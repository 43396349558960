import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Image from 'next/legacy/image'
import classNames from 'clsx'

import { workspaceSelector } from 'state/concepts/subdomain/selectors'

const WorkspaceLogo = ({ className }) => {
  const { name, logoUrl } = useSelector(workspaceSelector)

  return (
    <>
      {logoUrl && (
        <span className="mr-12 d-flex">
          <Image src={logoUrl} className="ws-logo-img" alt="Company logo" width={40} height={40} />
        </span>
      )}
      <div className={classNames(className, 'ws-logo-title font-600')} data-cy="workspace-name">
        {name}
      </div>
    </>
  )
}

WorkspaceLogo.propTypes = {
  className: PropTypes.string.isRequired,
}

export default WorkspaceLogo
