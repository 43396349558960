import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'clsx'
import { RemoveScroll } from 'react-remove-scroll'

import isLocationsSectionAvailable from 'utils/website/isLocationsSectionAvailable'
import isContactsSectionAvailable from 'utils/website/isContactsSectionAvailable'
import isReviewsSectionAvailable from 'utils/website/isReviewsSectionAvailable'
import isProvidersSectionAvailable from 'utils/website/isProvidersSectionAvailable'
import isServicesSectionAvailable from 'utils/website/isServicesSectionAvailable'
import { workspaceSelector, isClientPortalEnabledSelector } from 'state/concepts/subdomain/selectors'
import { websiteOrDraftSelector } from 'state/concepts/website/selectors'
import Cross from 'views/shared/icons/Cross'
import Button from 'views/shared/Button'
import BookingButton from 'views/Website/BookingButton'
import WorkspaceLogo from 'views/Website/WorkspaceLogo'

const MobileSidebar = ({ isSidebarVisible, onSidebarToggle }) => {
  const website = useSelector(websiteOrDraftSelector)
  const isClientPortalEnabled = useSelector(isClientPortalEnabledSelector)
  const { uniqCode } = useSelector(workspaceSelector)
  const showContacts = isContactsSectionAvailable(website)
  const showServices = isServicesSectionAvailable(website)
  const showProviders = isProvidersSectionAvailable(website)
  const showReviews = isReviewsSectionAvailable(website)
  const showLocations = isLocationsSectionAvailable(website)

  return (
    <RemoveScroll enabled={isSidebarVisible}>
      <div
        className={classNames('sidebar-animate', { 'sidebar-animate-open': isSidebarVisible })}
        role="button"
        data-cy="mobile-sidebar"
      >
        <aside className="ws-sidebar sidebar-wrap">
          <div className="d-flex align-items-center p-16">
            <div className="d-flex align-items-center mr-auto sidebar__workspace">
              <WorkspaceLogo className="in-blue-gray-800" />
            </div>
            <button className="pl-16 pr-16" type="button" onClick={onSidebarToggle} data-cy="sidebar-toggle-button">
              <Cross className="relative" color="#0C1E3E" size={10} dataCy="sidebar-cross-icon" />
            </button>
          </div>
          <div className="ws-sidebar__list mt-16">
            {showProviders && (
              <div className="pt-8 pb-8 pl-16 pr-16 font-600">
                <a href="#experts" data-cy="header-experts" onClick={onSidebarToggle}>
                  <FormattedMessage id="website.page.experts" />
                </a>
              </div>
            )}
            {showServices && (
              <div className="pt-8 pb-8 pl-16 pr-16 font-600">
                <a href="#services" data-cy="header-services" onClick={onSidebarToggle}>
                  <FormattedMessage id="website.page.services" />
                </a>
              </div>
            )}
            {showLocations && (
              <div className="pt-8 pb-8 pl-16 pr-16 font-600">
                <a href="#locations" data-cy="header-locations" onClick={onSidebarToggle}>
                  <FormattedMessage id="website.page.locations" />
                </a>
              </div>
            )}
            {showReviews && (
              <div className="pt-8 pb-8 pl-16 pr-16 font-600">
                <a href="#reviews" data-cy="header-reviews" onClick={onSidebarToggle}>
                  <FormattedMessage id="website.page.reviews" />
                </a>
              </div>
            )}
            {showContacts && (
              <div className="pt-8 pb-8 pl-16 pr-16 font-600">
                <a href="#contacts" data-cy="header-contacts" onClick={onSidebarToggle}>
                  <FormattedMessage id="website.page.contacts" />
                </a>
              </div>
            )}
          </div>
          <div className="d-flex mt-16 pl-16 pr-16">
            <BookingButton
              className="w-50 mr-16 ws-interactive-element"
              size="medium"
              dataCy="book-appointment-button"
            />
            {isClientPortalEnabled && (
              <a className="w-50 ws-interactive-element" href={`/client/${uniqCode}/bookings`}>
                <Button
                  text={{ id: 'website.page.clientPortal' }}
                  className="w-100 ws-interactive-element"
                  kind="secondary"
                  size="medium"
                  dataCy="client-portal-button"
                />
              </a>
            )}
          </div>
        </aside>
      </div>
    </RemoveScroll>
  )
}

MobileSidebar.propTypes = {
  isSidebarVisible: PropTypes.bool.isRequired,
  onSidebarToggle: PropTypes.func.isRequired,
}

export default MobileSidebar
