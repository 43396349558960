import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'

import { currentPageSelector, loadingSelector, pageCountSelector } from 'state/data/selectors'

const usePagination = ({ endpoint, revalidateAction }) => {
  const dispatch = useDispatch()
  const currentPage = useSelector(state => currentPageSelector(state, endpoint))
  const pageCount = useSelector(state => pageCountSelector(state, endpoint))
  const hasMore = useMemo(() => currentPage < pageCount, [currentPage, pageCount])
  const isLoading = useSelector(state => loadingSelector(state, endpoint))

  const onLoadMore = useCallback(() => {
    if (hasMore) {
      dispatch(revalidateAction({ number: currentPage + 1 }))
    }
  }, [dispatch, revalidateAction, hasMore, currentPage])

  useMount(() => dispatch(revalidateAction({ number: 1 })))

  return { onLoadMore, isLoading, hasMore }
}

export default usePagination
