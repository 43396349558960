import { useSelector } from 'react-redux'
import Image from 'next/legacy/image'
import { FormattedMessage } from 'react-intl'

import logoExpert from 'assets/images/logo_in_white.svg'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import isPresent from 'utils/isPresent'
import { MAIN_SITE_LINK } from 'lib/constants'
import isContactsSectionAvailable from 'utils/website/isContactsSectionAvailable'
import isReviewsSectionAvailable from 'utils/website/isReviewsSectionAvailable'
import { showModal as showModalAction } from 'state/modal/actions'
import { websiteOrDraftSelector } from 'state/concepts/website/selectors'
import isServicesSectionAvailable from 'utils/website/isServicesSectionAvailable'
import isProvidersSectionAvailable from 'utils/website/isProvidersSectionAvailable'
import isLocationsSectionAvailable from 'utils/website/isLocationsSectionAvailable'
import WorkspaceLogo from '../WorkspaceLogo'
import Contacts from './Contacts'

const Footer = () => {
  const website = useSelector(websiteOrDraftSelector)
  const { websiteSetting } = website
  const showProviders = isProvidersSectionAvailable(website)
  const showServices = isServicesSectionAvailable(website)
  const showContacts = isContactsSectionAvailable(website)
  const showReviews = isReviewsSectionAvailable(website)
  const showLocations = isLocationsSectionAvailable(website)
  const showPrivacyPolicy = websiteSetting.privacyPolicyEnabled && isPresent(websiteSetting.privacyPolicy)
  const showTerms = websiteSetting.termsAndConditionsEnabled && isPresent(websiteSetting.termsAndConditions)
  const showModal = useDispatchAction(showModalAction)

  const handleOpenPrivacyPolicy = () => {
    showModal({
      modalType: 'WEBSITE_WYSIWYG_MODAL',
      modalProps: {
        title: { id: 'website.privacyPolicy.modal.title' },
        content: websiteSetting.privacyPolicy,
      },
    })
  }

  const handleOpenTermsAndConditions = () => {
    showModal({
      modalType: 'WEBSITE_WYSIWYG_MODAL',
      modalProps: {
        title: { id: 'website.termsAndConditions.modal.title' },
        content: websiteSetting.termsAndConditions,
      },
    })
  }

  return (
    <footer className="ws-footer">
      {showContacts && <Contacts />}
      <div className="ws-footer__nav">
        <div className="container flex-wrap align-items-center d-flex justify-content-between">
          <div className="d-flex align-items-center ws-footer__workspace">
            <WorkspaceLogo className="in-white" />
          </div>
          <ul className="ws-footer__nav-list d-flex">
            {showProviders && (
              <li className="in-white ws-footer__nav-item font-600" data-cy="footer-experts">
                <a href="#experts">
                  <FormattedMessage id="website.page.experts" />
                </a>
              </li>
            )}
            {showServices && (
              <li className="in-white ws-footer__nav-item font-600" data-cy="footer-services">
                <a href="#services">
                  <FormattedMessage id="website.page.services" />
                </a>
              </li>
            )}
            {showLocations && (
              <li className="in-white ws-footer__nav-item font-600" data-cy="footer-locations">
                <a href="#locations">
                  <FormattedMessage id="website.page.locations" />
                </a>
              </li>
            )}
            {showReviews && (
              <li className="in-white ws-footer__nav-item font-600" data-cy="footer-reviews">
                <a href="#reviews">
                  <FormattedMessage id="website.page.reviews" />
                </a>
              </li>
            )}
            {showContacts && (
              <li className="in-white ws-footer__nav-item font-600" data-cy="footer-contacts">
                <a href="#contacts">
                  <FormattedMessage id="website.page.contacts" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="ws-footer__bottom">
        <div className="container flex-wrap align-items-center d-flex justify-content-between">
          <ul className="ws-footer__bottom-nav d-flex">
            {showPrivacyPolicy && (
              <li className="in-white">
                <button onClick={handleOpenPrivacyPolicy} data-cy="footer-privacy-policy">
                  <FormattedMessage id="website.page.privacyPolicy" />
                </button>
              </li>
            )}
            {showTerms && (
              <li className="in-white">
                <button onClick={handleOpenTermsAndConditions} data-cy="footer-terms-of-use">
                  <FormattedMessage id="website.page.termsOfUse" />
                </button>
              </li>
            )}
          </ul>
          <div className="ws-footer__bottom-nav d-flex align-items-center" data-cy="expertbox-block">
            <span className="mr-4 ws-footer__bottom-text" data-cy="expertbox-text">
              <FormattedMessage id="website.page.poweredBy" />
            </span>
            <a href={MAIN_SITE_LINK} target="_blank" data-cy="expertbox-main-site-link">
              <Image src={logoExpert} alt="site logo" width={112} height={42} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
