import PropTypes from 'prop-types'
import { Rate } from 'antd'
import { FormattedMessage } from 'react-intl'

import spanWithClass from 'utils/locales/spanWithClass'
import profileName from 'utils/profileName'
import { reviewCreatedAtDay } from 'utils/dateTime'
import ShowMoreText from 'views/shared/ShowMoreText'
import Avatar from 'views/shared/Avatar'
import ShowMoreButton from 'views/Website/ShowMoreButton'
import ShowLessButton from 'views/Website/ShowLessButton'

const Review = ({ text, rating, service, userProfile, clientProfile, updatedAt }) => (
  <div className="review-list__item" data-cy="review-item">
    <div className="d-flex align-items-center">
      <Avatar profile={clientProfile} />
      <div className="ml-12">
        <div className="overflow-x-hidden">
          <p className="mb-0 text-subheader font-600 truncate-text" data-cy="client-name">
            {profileName(clientProfile)}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Rate disabled defaultValue={rating} className="mr-12 stars-size-subheader ws-interactive-element" />
            <span className="in-blue-gray-300" data-cy="review-created-at">
              <FormattedMessage {...reviewCreatedAtDay(updatedAt)} />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-12" data-cy="service-with-text">
      <FormattedMessage
        id="website.page.reviews.forServiceWithProfile"
        values={{
          serviceName: service.name,
          profileName: profileName(userProfile),
          span: spanWithClass('in-gray-700'),
        }}
      />
    </div>
    <div className="mt-16">
      <ShowMoreText
        lines={3}
        text={text}
        className="text-subheader mb-0 ws-interactive-element"
        showMoreComponent={ShowMoreButton}
        showLessComponent={ShowLessButton}
      />
    </div>
  </div>
)

Review.defaultProps = {
  text: undefined,
}

Review.propTypes = {
  service: PropTypes.shape().isRequired,
  userProfile: PropTypes.shape().isRequired,
  clientProfile: PropTypes.shape().isRequired,
  rating: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default Review
