import isPresent from 'utils/isPresent'
import isAddressAvailable from './isAddressAvailable'
import isSocialNetworksAvailable from './isSocialNetworksAvailable'

const isContactsSectionAvailable = ({ workspace, socialNetwork, websiteSetting }) =>
  websiteSetting.contacts &&
  (isPresent(workspace.email) ||
    isPresent(workspace.phone) ||
    isAddressAvailable(workspace) ||
    isSocialNetworksAvailable(socialNetwork))

export default isContactsSectionAvailable
