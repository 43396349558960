import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { websiteOrDraftSelector } from 'state/concepts/website/selectors'
import VideoO from 'views/shared/icons/VideoO'

const OnlineAppointment = () => {
  const website = useSelector(websiteOrDraftSelector)
  const {
    widget: { hasOnlineServices },
  } = website

  return (
    hasOnlineServices && (
      <div className="ws-offline__wrap align-items-center d-flex mb-24" data-cy="online-location-item">
        <div className="ws-offline__icon flex-center">
          <VideoO size={20} className="in-blue-600" dataCy="online-location-icon" />
        </div>
        <div className="ml-24 ws-offline__info">
          <p className="font-600 text-title mb-2" data-cy="online-location-title">
            <FormattedMessage id="website.page.locations.onlineAppointments.title" />
          </p>
          <p className="in-gray-700 text-subheader mb-0" data-cy="online-location-description">
            <FormattedMessage id="website.page.locations.onlineAppointments.description" />
          </p>
        </div>
      </div>
    )
  )
}

export default OnlineAppointment
