import Head from 'next/head'
import { useSelector } from 'react-redux'

import { websiteSelector } from 'state/concepts/website/selectors'

const Meta = () => {
  const {
    websiteSetting: { pageTitle, metaDescription },
    workspace: { name },
  } = useSelector(websiteSelector)

  return (
    <Head>
      <title>{pageTitle || name}</title>
      {metaDescription && <meta data-cy="page-meta-description" name="description" content={metaDescription} />}
    </Head>
  )
}

export default Meta
