import PropTypes from 'prop-types'
import Image from 'next/legacy/image'

import imgFb from 'assets/images/socials/fb.svg'
import imgInsta from 'assets/images/socials/inst.svg'
import imgIn from 'assets/images/socials/in.svg'
import imgTwiter from 'assets/images/socials/twiter.svg'
import imgWup from 'assets/images/socials/wup.svg'
import imgYelp from 'assets/images/socials/yelp.svg'
import imgYoutube from 'assets/images/socials/youtube.svg'

const SocialNetworks = ({ facebookUrl, instagramUrl, whatsupUrl, youtubeUrl, yelpUrl, twitterUrl, linkedinUrl }) => (
  <div className="d-flex" data-cy="social-networks-links">
    {facebookUrl && (
      <a href={facebookUrl} className="mr-16" target="_blank" data-cy="facebook-url">
        <Image src={imgFb} alt="Facebook" width={32} height={32} />
      </a>
    )}
    {instagramUrl && (
      <a href={instagramUrl} className="mr-16" target="_blank" data-cy="instagram-url">
        <Image src={imgInsta} alt="Instagram" width={32} height={32} />
      </a>
    )}
    {whatsupUrl && (
      <a href={whatsupUrl} className="mr-16" target="_blank" data-cy="whatsup-url">
        <Image src={imgWup} alt="Whatsup" width={32} height={32} />
      </a>
    )}
    {youtubeUrl && (
      <a href={youtubeUrl} className="mr-16" target="_blank" data-cy="youtube-url">
        <Image src={imgYoutube} alt="Youtube" width={32} height={32} />
      </a>
    )}
    {linkedinUrl && (
      <a href={linkedinUrl} className="mr-16" target="_blank" data-cy="linkedin-url">
        <Image src={imgIn} alt="Linkedin" width={32} height={32} />
      </a>
    )}
    {twitterUrl && (
      <a href={twitterUrl} className="mr-16" target="_blank" data-cy="twitter-url">
        <Image src={imgTwiter} alt="Twitter" width={32} height={32} />
      </a>
    )}
    {yelpUrl && (
      <a href={yelpUrl} className="mr-16" target="_blank" data-cy="yelp-url">
        <Image src={imgYelp} alt="Yelp" width={32} height={32} />
      </a>
    )}
  </div>
)

SocialNetworks.defaultProps = {
  facebookUrl: undefined,
  instagramUrl: undefined,
  whatsupUrl: undefined,
  youtubeUrl: undefined,
  yelpUrl: undefined,
  twitterUrl: undefined,
  linkedinUrl: undefined,
}

SocialNetworks.propTypes = {
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  whatsupUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  yelpUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
}

export default SocialNetworks
