import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'

const Menu = ({ size, color, className, dataCy }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(className, 'va-middle')}
    fill={color}
    viewBox="0 0 1024 1024"
    data-cy={dataCy}
  >
    <path d="M0 56.89h1024v113.777h-1024v-113.777zM0 455.112h1024v113.777h-1024v-113.777zM0 853.334h1024v113.777h-1024v-113.777z" />
  </svg>
)

Menu.defaultProps = {
  size: 16,
  color: '#b1bac9',
  className: '',
  dataCy: undefined,
}

Menu.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
}

export default Menu
