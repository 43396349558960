import PropTypes from 'prop-types'
import Image from 'next/legacy/image'
import { Progress } from 'antd'

import rateImg from 'assets/images/reviewForm/rate.svg'

const RatingProgress = ({ rating, count, percentage }) => (
  <div className="reviews-progress__item d-flex align-items-center" data-cy="reviews-progress-item">
    <div className="reviews-progress__value mr-16 d-flex align-items-center">
      <Image src={rateImg} alt="Star" data-cy="star-icon" />
      <span className="ml-8 text-subheader" data-cy="reviews-progress-rating">
        {rating}
      </span>
    </div>
    <div className="reviews-progressbar" data-cy="reviews-progressbar">
      <Progress percent={percentage} showInfo={false} size="6px" className="progress--yellow" />
    </div>
    <div className="reviews-progress__qt ml-16">
      <span className="text-subheader in-gray-700" data-cy="reviews-progress-count">
        {count}
      </span>
    </div>
  </div>
)

RatingProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
}

export default RatingProgress
