import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Image from 'next/legacy/image'

import buildMapSearchUrl from 'utils/location/buildMapSearchUrl'
import buildStaticMapUrl from 'utils/location/buildStaticMapUrl'

const OfflineLocation = ({ coordinate, name, addressLine }) => (
  <div className="ws-online__item" data-cy="offline-location-item">
    <p className="mb-2 font-600 text-title" data-cy="offline-location-name">
      {name}
    </p>
    <p className="mb-4 text-subheader in-gray-700" data-cy="offline-location-address">
      {addressLine}
    </p>
    <p className="in-blue-600 text-subheader mb-0">
      <a target="_blank" href={buildMapSearchUrl(coordinate)} data-cy="offline-location-google-link">
        <FormattedMessage id="website.page.locations.viewOnGoogle" />
      </a>
    </p>
    <Image
      className="ws-map-mob"
      width={343}
      height={120}
      src={buildStaticMapUrl([{ coordinate }], { width: 686, height: 240, zoom: 14 })}
      alt="Map"
    />
  </div>
)

OfflineLocation.propTypes = {
  name: PropTypes.string.isRequired,
  addressLine: PropTypes.string.isRequired,
  coordinate: PropTypes.shape().isRequired,
}

export default OfflineLocation
