import Image from 'next/legacy/image'
import { useSelector } from 'react-redux'
import { useMount } from 'react-use'

import isPresent from 'utils/isPresent'
import buildStaticMapUrl from 'utils/location/buildStaticMapUrl'
import Spinner from 'views/shared/icons/Spinner'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { fetchPublicLocationsEndpoint } from 'state/concepts/website/endpoints'
import { loadingSelector } from 'state/data/selectors'
import { fetchPublicLocations as fetchPublicLocationsAction } from 'state/concepts/website/actions'
import { locationsSelector, websiteOrDraftSelector } from 'state/concepts/website/selectors'
import OfflineLocation from './OfflineLocation'

const OfflineLocations = () => {
  const website = useSelector(websiteOrDraftSelector)
  const {
    workspace: { uniqCode },
    widget: { id },
  } = website
  const fetchPublicLocations = useDispatchAction(fetchPublicLocationsAction)
  const { endpoint } = fetchPublicLocationsEndpoint(uniqCode, id)
  const isLoading = useSelector(state => loadingSelector(state, endpoint))
  const locations = useSelector(locationsSelector)
  useMount(fetchPublicLocations)

  if (isLoading !== false) return <Spinner />

  return (
    isPresent(locations) && (
      <div className="ws-online__wrap d-flex">
        <div className="ws-online__list">
          {locations.map(location => (
            <OfflineLocation key={location.id} {...location} />
          ))}
        </div>
        <div className="ws-online__map" data-cy="locations-map">
          <Image
            width={644}
            height={536}
            src={buildStaticMapUrl(locations, { width: 644, height: 536 })}
            alt="locations-map"
          />
        </div>
      </div>
    )
  )
}

export default OfflineLocations
