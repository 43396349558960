import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import classNames from 'clsx'

import { SIDEBAR_COMPONENTS_TYPES } from 'lib/constants/sidebar'
import { WEBSITE_BACKGROUND_IMAGES } from 'lib/constants/website'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import isContactsSectionAvailable from 'utils/website/isContactsSectionAvailable'
import isReviewsSectionAvailable from 'utils/website/isReviewsSectionAvailable'
import isProvidersSectionAvailable from 'utils/website/isProvidersSectionAvailable'
import isServicesSectionAvailable from 'utils/website/isServicesSectionAvailable'
import isLocationsSectionAvailable from 'utils/website/isLocationsSectionAvailable'
import { workspaceSelector, isClientPortalEnabledSelector } from 'state/concepts/subdomain/selectors'
import { websiteOrDraftSelector } from 'state/concepts/website/selectors'
import { showSidebar } from 'state/sidebar/actions'
import Menu from 'views/shared/icons/Menu'
import Button from 'views/shared/Button'
import BookingButton from '../BookingButton'
import WorkspaceLogo from '../WorkspaceLogo'
import MobileSidebar from './MobileSidebar'

const Header = ({ isPreview, toggleEditWebsite }) => {
  const [isSectionEdited, toggleEditState] = useToggle(false)
  const [isSidebarVisible, onSidebarToggle] = useToggle(false)
  const { uniqCode } = useSelector(workspaceSelector)
  const handleEditWebsite = useDispatchAction(showSidebar, {
    sidebarType: SIDEBAR_COMPONENTS_TYPES.websiteEdit,
    sidebarProps: { toggleEditWebsite },
    animate: false,
  })
  const isClientPortalEnabled = useSelector(isClientPortalEnabledSelector)
  const website = useSelector(websiteOrDraftSelector)
  const showContacts = isContactsSectionAvailable(website)
  const showServices = isServicesSectionAvailable(website)
  const showProviders = isProvidersSectionAvailable(website)
  const showReviews = isReviewsSectionAvailable(website)
  const showLocations = isLocationsSectionAvailable(website)
  const handleEditTitle = useDispatchAction(showSidebar, {
    sidebarType: SIDEBAR_COMPONENTS_TYPES.websiteWelcomeSection,
    sidebarProps: { toggleEditState },
    animate: false,
  })

  return (
    <>
      <header className="main-header ws-main-header d-flex align-items-center">
        <div className="d-flex align-items-center mr-auto ws-header__item">
          <WorkspaceLogo className="in-blue-gray-800" />
        </div>
        <div className="ws-header__nav d-flex align-items-center hidden-md justify-content-center ws-header__item">
          {showProviders && (
            <a href="#experts" className="ml-20 hover-blue-600 mr-20 font-600" data-cy="header-experts">
              <FormattedMessage id="website.page.experts" />
            </a>
          )}
          {showServices && (
            <a href="#services" className="ml-20 hover-blue-600 mr-20 font-600" data-cy="header-services">
              <FormattedMessage id="website.page.services" />
            </a>
          )}
          {showLocations && (
            <a href="#locations" className="ml-20 hover-blue-600 mr-20 font-600" data-cy="header-locations">
              <FormattedMessage id="website.page.locations" />
            </a>
          )}
          {showReviews && (
            <a href="#reviews" className="ml-20 hover-blue-600 mr-20 font-600" data-cy="header-reviews">
              <FormattedMessage id="website.page.reviews" />
            </a>
          )}
          {showContacts && (
            <a href="#contacts" className="ml-20 hover-blue-600 mr-20 font-600" data-cy="header-contacts">
              <FormattedMessage id="website.page.contacts" />
            </a>
          )}
        </div>
        <div className="d-flex ml-auto ws-header__item justify-content-end">
          {isClientPortalEnabled && (
            <a className="ws-interactive-element" href={`/client/${uniqCode}/bookings`}>
              <Button
                text={{ id: 'website.page.clientPortal' }}
                kind="secondary"
                size="medium"
                className="mr-16 hidden-md ws-interactive-element"
                dataCy="header-client-portal-button"
              />
            </a>
          )}
          <BookingButton className="ws-interactive-element" dataCy="header-book-appointment-button" />
          <button
            className="visible-md ml-16 ws-mob-btn"
            type="button"
            onClick={onSidebarToggle}
            data-cy="sidebar-toggle-button"
          >
            <Menu className="relative" color="#0C1E3E" size={18} dataCy="sidebar-menu-icon" />
          </button>
          {isPreview && (
            <Button
              text={{ id: 'websitePreview.editWebsite' }}
              kind="secondary"
              icon="edit-pencil"
              className="main-btn--white ws-header__hero-section-edit-btn ws-edit-website"
              onClick={handleEditWebsite}
            />
          )}
        </div>
      </header>
      <div
        className="ws-header"
        style={{ backgroundImage: `url(${WEBSITE_BACKGROUND_IMAGES[website.backgroundImage].src})` }}
        data-cy="website-header"
      >
        <MobileSidebar isSidebarVisible={isSidebarVisible} onSidebarToggle={onSidebarToggle} />
        <div
          className={classNames('ws-header__hero-section-wrap', { 'ws-header--edit': isSectionEdited })}
          data-cy="welcome-section-wrapper"
        >
          {isPreview && (
            <Button
              text={{ id: 'websitePreview.editWelcomeSection' }}
              kind="secondary"
              icon="edit-pencil"
              className="main-btn--white ws-header__hero-section-edit-btn"
              onClick={handleEditTitle}
              dataCy="header-edit-button"
            />
          )}
          <div className="container">
            <div className="ws-header__hero-section">
              <p className="ws-header__hero-section-title" data-cy="header-section-title">
                {prop('headerTitle', website)}
              </p>
              <p className="ws-header__hero-section-subtitle" data-cy="header-section-subtitle">
                {prop('headerSubtitle', website)}
              </p>
              <div className="d-flex justify-content-center">
                <BookingButton className="mr-16 ws-interactive-element" dataCy="book-appointment-button" />
                {isClientPortalEnabled && (
                  <a className="ws-interactive-element" href={`/client/${uniqCode}/bookings`}>
                    <Button
                      text={{ id: 'website.page.clientPortal' }}
                      className="ws-interactive-element"
                      kind="secondary"
                      size="medium"
                      dataCy="client-portal-button"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Header.defaultProps = {
  isPreview: false,
  toggleEditWebsite: undefined,
}

Header.propTypes = {
  isPreview: PropTypes.bool,
  toggleEditWebsite: PropTypes.func,
}

export default Header
