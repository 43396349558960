import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Button from 'views/shared/Button'
import { fetchPublicProvidersEndpoint } from 'state/concepts/website/endpoints'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { providersSelector, websiteOrDraftSelector } from 'state/concepts/website/selectors'
import { fetchPublicProviders } from 'state/concepts/website/actions'
import Spinner from 'views/shared/icons/Spinner'
import isPresent from 'utils/isPresent'
import usePagination from '../hooks/usePagination'
import Provider from './Provider'

const Providers = () => {
  const { uniqCode } = useSelector(workspaceSelector)
  const { widget } = useSelector(websiteOrDraftSelector)
  const providers = useSelector(providersSelector)
  const { onLoadMore, isLoading, hasMore } = usePagination({
    endpoint: fetchPublicProvidersEndpoint(uniqCode, widget.id).endpoint,
    revalidateAction: fetchPublicProviders,
  })
  const isInitialLoading = isLoading !== false && !isPresent(providers)

  return (
    <div className="ws-section" data-cy="experts-section">
      <div className="container ws-container--full-mob">
        <p className="font-600 text-center ws-section__title" id="experts" data-cy="experts-section-title">
          <FormattedMessage id="website.page.providers.title" />
        </p>
        {isInitialLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="ws-expert d-flex">
              {providers.map(provider => (
                <Provider key={provider.id} {...provider} />
              ))}
            </div>
            {hasMore && (
              <Button
                icon="arrow-down-long"
                iconSize={14}
                text={{ id: 'website.page.providers.showMore' }}
                kind="secondary"
                className="w-100 mt-24 main-btn--reverse ws-more-btn"
                size="medium"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={onLoadMore}
                dataCy="show-more-experts-button"
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Providers
