import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { bootWidget } from 'utils/widgets/bootWidget'
import { websiteSelector } from 'state/concepts/website/selectors'
import Button from 'views/shared/Button'

const BookingButton = ({ serviceId, providerId, ...props }) => {
  const website = useSelector(websiteSelector)

  const handleOpenWidget = event => {
    event.stopPropagation()
    bootWidget({ ...website, serviceId, providerId }, true)
  }

  return <Button text={{ id: 'website.page.bookAppointment' }} size="medium" {...props} onClick={handleOpenWidget} />
}

BookingButton.defaultProps = {
  serviceId: undefined,
  providerId: undefined,
}

BookingButton.propTypes = {
  serviceId: PropTypes.string,
  providerId: PropTypes.string,
}

export default BookingButton
