import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Rate } from 'antd'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import { round } from 'lodash'

import { ROUND_PRECISION } from 'lib/constants/reviews'
import { ratingsWithProgress } from 'utils/reviews'
import { fetchPublicWebsiteReviewsEndpoint } from 'state/concepts/website/endpoints'
import { fetchPublicWebsiteReviews } from 'state/concepts/website/actions'
import {
  websiteOrDraftSelector,
  reviewsSelector,
  fetchPublicWebsiteReviewsMetaSelector,
} from 'state/concepts/website/selectors'
import Spinner from 'views/shared/icons/Spinner'
import Button from 'views/shared/Button'
import usePagination from 'views/Website/hooks/usePagination'
import Review from './Review'
import RatingProgress from './RatingProgress'

const Reviews = () => {
  const {
    widget,
    workspace: { uniqCode },
  } = useSelector(websiteOrDraftSelector)
  const { endpoint } = fetchPublicWebsiteReviewsEndpoint(uniqCode, widget.id)
  const reviews = useSelector(reviewsSelector)
  const reviewsMeta = useSelector(state => fetchPublicWebsiteReviewsMetaSelector(state, uniqCode, widget.id))
  const progressProps = useMemo(() => ratingsWithProgress(reviewsMeta), [reviewsMeta])
  const averageRating = useMemo(() => round(reviewsMeta.averageRating, ROUND_PRECISION), [reviewsMeta.averageRating])
  const { onLoadMore, isLoading, hasMore } = usePagination({ revalidateAction: fetchPublicWebsiteReviews, endpoint })
  const isInitialLoading = isLoading !== false && reviews.length === 0

  return (
    <div className="ws-section" data-cy="reviews-section">
      <div className="container ws-container--full-mob">
        <p className="font-600 ws-section__title text-center mb-42" id="reviews" data-cy="reviews-section-title">
          <FormattedMessage id="website.page.reviews.title" />
        </p>
        {isInitialLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="ws-review bg-white text-center">
              <div className="ws-review__info" data-cy="review-average-rating-summary">
                <p className="text-headline font-600 mb-4" data-cy="review-average-rating">
                  <FormattedMessage id="website.page.reviews.averageRating" values={{ rating: averageRating }} />
                </p>
                <Rate defaultValue={averageRating} disabled allowHalf className="mb-4 ws-interactive-element" />
                <p className="text-subheader in-gray-700 mb-0" data-cy="reviews-count">
                  <FormattedMessage id="website.page.reviews.reviewsCount" values={{ count: reviewsMeta.total }} />
                </p>
              </div>
              <div className="reviews-progress" data-cy="rating-progress-section">
                {progressProps.map(props => (
                  <RatingProgress key={prop('rating', props)} {...props} />
                ))}
              </div>
            </div>
            <div className="ws-review__wrap d-flex">
              {reviews.map(review => (
                <Review key={review.id} {...review} />
              ))}
            </div>
            {hasMore && (
              <Button
                icon="arrow-down-long"
                iconSize={14}
                text={{ id: 'website.page.reviews.loadMore' }}
                kind="secondary"
                className="w-100 mt-24 main-btn--reverse ws-more-btn"
                size="medium"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onLoadMore}
                dataCy="load-more-button"
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Reviews
