import { useSelector } from 'react-redux'
import Image from 'next/legacy/image'
import { FormattedMessage } from 'react-intl'

import imgFooter from 'assets/images/website/footer-img.svg'
import isSocialNetworksAvailable from 'utils/website/isSocialNetworksAvailable'
import isAddressAvailable from 'utils/website/isAddressAvailable'
import { websiteSelector } from 'state/concepts/website/selectors'
import Envelope from 'views/shared/icons/Envelope'
import Phone from 'views/shared/icons/Phone'
import Address from './Address'
import SocialNetworks from './SocialNetworks'

const Contacts = () => {
  const { workspace, socialNetwork } = useSelector(websiteSelector)
  const { email, phone } = workspace
  const showAddress = isAddressAvailable(workspace)
  const showSocialNetworks = isSocialNetworksAvailable(socialNetwork)

  return (
    <div className="ws-footer__top">
      <div className="container d-flex justify-content-between">
        <div data-cy="contacts-section" className="ws-footer__conact">
          <p
            id="contacts"
            className="ws-footer__title text-display font-600 in-white mb-24"
            data-cy="footer-cotacts-header"
          >
            <FormattedMessage id="website.page.contacts" />
          </p>
          {showAddress && <Address {...workspace} />}
          {email && (
            <div className="d-flex mb-24" data-cy="email">
              <Envelope className="in-blue-600" size={20} dataCy="email-icon" />
              <a
                href={`mailto:${email}`}
                className="ws-footer__conact-item text-subheader ml-16 ws-interactive-element"
              >
                {email}
              </a>
            </div>
          )}
          {phone && (
            <div className="d-flex mb-24" data-cy="phone">
              <Phone className="in-blue-600" size={20} dataCy="phone-icon" />
              <a className="ws-footer__conact-item text-subheader ml-16 ws-interactive-element" href={`tel:${phone}`}>
                {phone}
              </a>
            </div>
          )}
          {showSocialNetworks && <SocialNetworks {...socialNetwork} />}
        </div>
        <div className="ws-footer__img pt-40" data-cy="footer-image">
          <Image src={imgFooter} alt="Footer image" />
        </div>
      </div>
    </div>
  )
}

export default Contacts
