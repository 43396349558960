import { GOOGLE_MAPS_API_KEY } from 'lib/constants'

const buildStaticMapUrl = (locations, { width, height, zoom } = { width: 640, height: 640 }) => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
  const size = `size=${width}x${height}`
  const mapType = 'maptype=roadmap'
  const apiKey = `key=${GOOGLE_MAPS_API_KEY}`
  const zoomLevel = zoom ? `&zoom=${zoom}` : ''
  const markers = locations.map(({ coordinate }) => `markers=color:blue%7C${coordinate.x},${coordinate.y}`).join('&')

  return `${baseUrl}?${size}${zoomLevel}&${mapType}&${markers}&${apiKey}`
}

export default buildStaticMapUrl
