import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Button from 'views/shared/Button'
import { fetchPublicServicesEndpoint } from 'state/concepts/website/endpoints'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { servicesSelector, websiteOrDraftSelector } from 'state/concepts/website/selectors'
import { fetchPublicServices } from 'state/concepts/website/actions'
import Spinner from 'views/shared/icons/Spinner'
import isPresent from 'utils/isPresent'
import usePagination from '../hooks/usePagination'
import Service from './Service'

const Services = () => {
  const { uniqCode } = useSelector(workspaceSelector)
  const { widget } = useSelector(websiteOrDraftSelector)
  const services = useSelector(servicesSelector)
  const { onLoadMore, isLoading, hasMore } = usePagination({
    endpoint: fetchPublicServicesEndpoint(uniqCode, widget.id).endpoint,
    revalidateAction: fetchPublicServices,
  })
  const isInitialLoading = isLoading !== false && !isPresent(services)

  return (
    <div className="ws-section" data-cy="services-section">
      <div className="container ws-container--full-mob">
        <p className="font-600 text-center ws-section__title" id="services" data-cy="services-section-title">
          <FormattedMessage id="website.page.services.title" />
        </p>
        {isInitialLoading ? (
          <Spinner />
        ) : (
          <>
            {services.map(service => (
              <Service key={service.id} {...service} />
            ))}
            {hasMore && (
              <Button
                icon="arrow-down-long"
                iconSize={14}
                text={{ id: 'website.page.services.showMore' }}
                kind="secondary"
                className="w-100 mt-24 main-btn--reverse ws-more-btn"
                size="medium"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={onLoadMore}
                dataCy="show-more-services-button"
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Services
